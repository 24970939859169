.fullTitle {
    display: block;
    width: 100%;
    background-color: $vermelho;
    padding: 110px 0 10px 0;
    h1 {
        display: block;
        text-align: right;
        color: #fff;
        font-family: $fontTitulo;
        font-size: 40px;
        letter-spacing: -0.02px;
    }
}