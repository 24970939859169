header {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #FFF;
    top: 0;
    z-index: 999;
    transition: all .3s ease;
    padding: 14px 0;
    box-shadow: 0 2px 4px 0 rgba(190,190,190,0.50);

    .logoHeader {
        margin-bottom: -40px;
        display: block;
    }

    .menu {
        display: block;
        .botaoResponsivo {
            position: relative;
            display: none;
            z-index: 9999;
            width: 50px;
            height: 50px;
            float: right;
            // top: -22px;
            // right: 15px;
            @include breakpoint(tablet) {
                display: block;
            }
            .linhasmr {
                float: right;
                margin-top: 8px;
                .b1 {
                    top: 14px;
                }
                .b2 {
                    top: 22px;
                }
                .b3 {
                    top: 30px;
                }
                >span {
                    background-color: $vermelho;
                    height: 2px;
                    right: 0;
                    position: absolute;
                    top: 0;
                    transition: all 0.3s ease 0s;
                    width: 32px;
                    border-radius: 0px;
                }
            }
            &.active {
                // position: fixed;
                // right: 95px;
                // top: 30px;
                @include breakpoint(mob) {
                    // right: 30px;
                }
                .linhasmr {
                    >span {
                        background-color: $vermelho !important;
                    }
                    .b1 {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        top: 21px;
                        right: 0;
                    }
                    .b2 {
                        width: 0px;
                    }
                    .b3 {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        top: 21px;
                        right: 0;
                    }
                }
            }
        }
        nav {
            display: block;
            width: 100%;
            @include breakpoint(tablet) {
                position: fixed;
                right: -100vw;
                height: 100vh;
                width: 100vw;
                top: 0;
                background-color: #FFF;
                transition: all .3s ease;
            }
            ul {
                display: flex;
                justify-content: space-between;
                margin: 0;
                padding: 0;
                @include breakpoint(tablet) {
                    padding: 0 30px;
                    display: block;
                }
                li {
                    list-style-type: none;
                    display: inline-block;
                    @include breakpoint(tablet) {
                        display: block;
                    }
                    a {
                        font-family: $fontTitulo;
                        position: relative;
                        display: block;
                        color: #000;
                        font-size: 18px;
                        line-height: normal;
                        letter-spacing: -0.09px;
                        padding: 8px 9px;
                        transition: all .3s ease;
                        text-decoration: none;
                        text-transform: lowercase;
                        @include breakpoint(tablet) {
                            border-bottom: #f1f1f1 1px solid;
                            padding: 16px 0;
                        }
                        &::before {
                            position: absolute;
                            content: '';
                            width: 0;
                            left: 50%;
                            bottom: 0;
                            height: 2px;
                            transform: translateX(-50%);
                            background-color: $vermelho;
                            transition: all .3s ease;
                        }
                        &:hover {
                            cursor: pointer;
                            color: $vermelho;
                            &::before {
                                width: 100%;
                            }
                        }
                    }
                    &.active {
                        a {
                            color: $vermelho;
                            &::before {
                                width: 100%;
                            }
                        }
                    }
                    &.linksHeader {
                        display: block;
                    }
                }
            }
            &.active {
                right: 0;
            }
        }
        .mobile {
            padding: 30px;
            .redes {
                text-align: left;
                ul {
                    padding: 0;
                    text-align: left;
                    li {
                        display: inline-block;
                        a {
                            border: 0;
                            font-size: 30px;
                            span {
                                display: inline-block;
                                font-size: 20px;
                                font-family: $fontDefault;
                            }
                        }
                    }
                }
            }
        }
    }

    .redes {
        display: block;
        text-align: right;
        ul {
            display: inline-block;
            width: 100%;
            text-align: right;
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                list-style-type: none;
                margin-left: 8px;
                a {
                    text-decoration: none;
                    font-size: 30px;
                    color: #000;
                    @include breakpoint(tablet) {
                        font-size: 24px;
                    }
                    span {
                        display: inline-block;
                        font-size: 20px;
                        @include breakpoint(tablet) {
                            display: none;
                        }
                    }
                }
                &:last-child {
                    a {
                        font-size: 26px;
                        @include breakpoint(tablet) {
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }

    &.noBack {}

    &.active, &.scroll {
    }

    &.forceScroll {}
    
}