.ondeEncontrar {
    display: block;
    width: 100%;
    height: auto;
    padding: 80px 0 0;

    h2 {
        font-family: $fontTitulo;
        font-size: 32px;
        color: #2B2B2B;
        letter-spacing: -0.16px;
        margin-bottom: 22px;
    }

    .linha {
        display: block;
        width: 100%;
        max-width: 85px;
        height: 1px;
        background-color: $vermelho;
        margin-bottom: 22px;
    }

    .texto {
        font-size: 18px;
        color: #4A4743;
        letter-spacing: -0.09px;
        margin-bottom: 22px;
    }

    .mapa {
        display: block;
        width: 100%;
        height: 100vh;
        max-height: 640px;
    }

    .table {
        font-size: 12px;
    }

    form {
        .form-group {
            margin-bottom: 12px;
            .form-control {
                border: 1px solid #676767;
                height: auto;
                font-size: 17px;
                color: #4A4743;
                letter-spacing: -0.09px;
                border-radius: 0;
                padding: 13px;
                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: #4A4743;
                }
                ::-moz-placeholder { /* Firefox 19+ */
                    color: #4A4743;
                }
                :-ms-input-placeholder { /* IE 10+ */
                    color: #4A4743;
                }
                :-moz-placeholder { /* Firefox 18- */
                    color: #4A4743;
                }
            }
        }
    }

}