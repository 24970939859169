.vitrine {
    display: block;
    width: 100%;
    height: auto;
    border-bottom: $vermelho 7px solid;

    .carouselVitrine {
        display: block;
        width: 100%;
        height: 100vh;
        max-height: 560px;

        .item {
            display: block;
            width: 100%;
            height: 100vh;
            max-height: 560px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

    }

}