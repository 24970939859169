.catalogo {
    display: block;
    width: 100%;
    height: auto;

    .pageTaxonomy {
        display: block;
        margin: 60px 0;
    }

    h2 {
        font-family: $fontTitulo;
        font-size: 34px;
        color: #2B2B2B;
        letter-spacing: -0.17px;
        margin-bottom: 30px;
    }

    nav {
        margin: 0;
        padding: 0;
        @include breakpoint(tablet) {
            margin-bottom: 40px;
        }
        ul {
            margin: 0;
            padding: 0 20px 0 0;
            @include breakpoint(tablet) {
                padding: 0;
            }
            li {
                list-style-type: none;
                a {
                    display: block;
                    text-decoration: none;
                    width: 100%;
                    height: auto;
                    font-size: 15px;
                    color: #4A4743;
                    letter-spacing: -0.08px;
                    line-height: 42px;
                    border-bottom: $vermelho 1px solid;
                    &:hover {
                        color: $vermelhoHover;
                    }
                }
            }
        }
    }

    .listagemProdutos {
        a {
            display: block;
            border: $vermelho 1px solid;
            margin-bottom: 30px;
            text-decoration: none;
            .foto {
                padding: 30px;
            }
            h3 {
                display: block;
                background-color: $vermelho;
                color: #fff;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.08px;
                text-align: center;
                padding: 9px 15px;
                min-height: 60px;
                margin-bottom: 0;
            }
        }
    }
    
    .paginacao {
        display: block;
        padding: 30px 0;
        margin-bottom: 30px;
        .wp-pagenavi {
            a, span {
                padding: 10px;
                &.current {
                    background-color: #2b2b2b;
                    border-color: #2b2b2b;
                    color: #fff;
                }
            }
            a {
                &:hover {
                    background-color: $vermelhoHover;
                    border-color: $vermelhoHover;
                    color: #fff;
                }
            }
        }
    }

    .pageSingle {
        display: block;
        margin: 60px 0;
        .foto {
            border: 1px solid #E6E6E6;
            display: block;
            padding: 30px;
            @include breakpoint(tablet) {
                margin-bottom: 40px;
            }
        }
    }

    .compartilhe {
        display: block;
        h3 {
            font-weight: bold;
            font-size: 12px;
            color: #4A4743;
            letter-spacing: -0.06px;
            line-height: 42px;
            margin-bottom: 0;
        }
        nav {
            display: block;
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style-type: none;
                    display: inline-block;
                    a {
                        text-decoration: none;
                        border-bottom: 0;
                    }
                }
            }
        }
    }

}