.sobre {
    display: block;
    width: 100%;
    height: auto;
    padding: 200px 0 0;
    @include breakpoint(tablet) {
        padding-top: 100px;
    }
    
    .pl40 {
        padding-left: 40px;
        @include breakpoint(tablet) {
            padding-left: 0;
        }
    }

    picture {
        @include breakpoint(tablet) {
            display: block;
            margin-bottom: 40px;
        }
    }

    h2 {
        font-family: $fontTitulo;
        font-size: 32px;
        color: $vermelho;
        letter-spacing: -0.16px;
        margin-bottom: 22px;
    }

    .linha {
        display: block;
        width: 100%;
        max-width: 85px;
        height: 1px;
        background-color: $vermelho;
        margin-bottom: 22px;
    }

    .texto {
        font-size: 16px;
        color: #4A4743;
        letter-spacing: -0.08px;
        line-height: 34px;
        margin-bottom: 22px;
    }

}