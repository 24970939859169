.template-onde-encontrar {
    display: block;
    width: 100%;
    height: auto;
    background-image: url($img + 'encontrar/background.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 60px 0;
    color: #fff;

    h2 {
        font-family: $fontTitulo;
        font-size: 40px;
        color: #FFFFFF;
        letter-spacing: -0.2px;
        margin-bottom: 15px;
    }
    .linha {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #fff;
        max-width: 85px;
        margin-bottom: 15px;
    }
    .texto {
        display: block;
        font-size: 20px;
        line-height: normal;
        color: #FFFFFF;
        letter-spacing: -0.1px;
        margin-bottom: 20px;
    }
    a {
        font-size: 30px;
        padding: 5px 25;
        line-height: normal;
        height: auto;
        span {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            font-weight: bold;
        }
    }

}