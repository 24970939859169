@mixin breakpoint($breakpoint, $break: null) {
  @if $break != null {
    $mediaVal: $breakpoint;

    @each $type, $value in $breakpoints {
      @if map-has-key($value, $breakpoint) {
        $mediaVal: map-get($value, $breakpoint);
      }
    }

    @media screen and (#{$break}-width: #{$mediaVal}) {
      @content;
    }
  } @else {
    @each $type, $value in $breakpoints {
      @if map-has-key($value, $breakpoint) {
        @media screen and (#{$type}-width: #{map-get($value, $breakpoint)}) {
          @content;
        }
      }
    }
  }
}

@function lineHeight($fontSize, $lineHeight) {
  @return #{$fontSize}/#{$lineHeight};
}

@mixin italization($degrees:-7) {
  -moz-transform: skewX($degrees * 1deg) skewY(0deg);
  -webkit-transform: skewX($degrees * 1deg) skewY(0deg);
  -o-transform: skewX($degrees * 1deg) skewY(0deg);
  -ms-transform: skewX($degrees * 1deg) skewY(0deg);
  transform: skewX($degrees * 1deg) skewY(0deg);
}

@mixin bgGrayColor($colorFrom: #f9f9f9, $colorTo: #cccccc) {
  background: $colorFrom;
  background: -moz-linear-gradient(-45deg, $colorFrom 45%, $colorTo 100%);
  background: -webkit-linear-gradient(-45deg, $colorFrom 45%, $colorTo 100%);
  background: linear-gradient(135deg, $colorFrom 45%, $colorTo 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$colorFrom', endColorstr='$colorTo', GradientType=1);
  background-image: url($img + 'gerais/back-cinza.jpg');
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

@mixin bgMixColor() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c0266a+0,6bc9ff+100 */
  background: rgb(192, 38, 106);
  background: -moz-linear-gradient(45deg, rgba(192, 38, 106, 1) 0%, rgba(107, 201, 255, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(192, 38, 106, 1) 0%, rgba(107, 201, 255, 1) 100%);
  background: linear-gradient(45deg, rgba(192, 38, 106, 1) 0%, rgba(107, 201, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c0266a', endColorstr='#6bc9ff', GradientType=1);
}

@mixin bgMixColorHover() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c0266a+0,6bc9ff+100 */
  background: rgb(192, 38, 106);
  background: -moz-linear-gradient(-45deg, rgba(192, 38, 106, 1) 0%, rgba(107, 201, 255, 1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(192, 38, 106, 1) 0%, rgba(107, 201, 255, 1) 100%);
  background: linear-gradient(135deg, rgba(192, 38, 106, 1) 0%, rgba(107, 201, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c0266a', endColorstr='#6bc9ff', GradientType=1);
}

@mixin bgBackNone() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0); /* IE6-9 */
}

@mixin paralelograma() {
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

@mixin paragonBorder() {
  -webkit-clip-path: polygon(0 1%, 90% 1%, 100% 100%, 10% 100%);
  clip-path: polygon(0 1%, 90% 1%, 100% 100%, 10% 100%);
}

@mixin background-2x($path, $ext: "png", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {

  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";
 
  background-image: url("#{$at1x_path}");
  background-size: $w $h;
  background-position: $pos;
  background-repeat: $repeat;
 
  @media all and (-webkit-min-device-pixel-ratio : 1.5),
  all and (-o-min-device-pixel-ratio: 3/2),
  all and (min--moz-device-pixel-ratio: 1.5),
  all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}"); 
  }
}