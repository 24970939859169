@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@font-face {
    font-family: 'Androgyne';
    src: url($fontsSRC + 'AndrogyneMedium.eot');
    src: url($fontsSRC + 'AndrogyneMedium.eot?#iefix') format('embedded-opentype'),
        url($fontsSRC + 'AndrogyneMedium.woff2') format('woff2'),
        url($fontsSRC + 'AndrogyneMedium.woff') format('woff'),
        url($fontsSRC + 'AndrogyneMedium.ttf') format('truetype'),
        url($fontsSRC + 'AndrogyneMedium.svg#AndrogyneMedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}