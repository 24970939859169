footer {
    display: block;
    width: 100%;
    height: auto;
    background-color: $vermelho;
    padding: 60px 0 0;
    border-top: #4A4743 7px solid;

    .frase {
        font-family: $fontTitulo;
        font-size: 32px;
        line-height: normal;
        color: #FFFFFF;
        letter-spacing: -0.18px;
        text-align: center;
        text-shadow: 0 2px 4px rgba(0,0,0,0.50);
        margin-bottom: 15px;
    }

    .logoFooter {
        margin-bottom: 30px;
    }

    .menuFooter {
        display: block;
        position: relative;
        &::before {
            position: absolute;
            content: '';
            left: -15px;
            width: 1px;
            height: 100%;
            background-color: $vermelhoHover;
        }
        nav {
            @include breakpoint(tablet) {
                margin-bottom: 30px;
            }
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style-type: none;
                    a {
                        text-decoration: none;
                        color: #fff;
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: -0.09px;
                        @include breakpoint(tablet) {
                            line-height: 32px;
                        }
                        &:hover {
                            color: #f1f1f1;
                        }
                    }
                }
            }
        }
    }

    .redes {
        display: block;
        text-align: left;
        margin: 30px 0;
        ul {
            display: inline-block;
            width: 100%;
            text-align: left;
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                list-style-type: none;
                margin-left: 8px;
                a {
                    text-decoration: none;
                    font-size: 30px;
                    color: #FFF;
                    span {
                        display: inline-block;
                        font-size: 20px;
                    }
                }
                &:last-child {
                    a {
                        font-size: 26px;
                    }
                }
            }
        }
    }

    .copyright {
        display: block;
        width: 100%;
        background-color: #FFF;
        padding: 30px 0;
        font-size: 12px;
        color: #4A4743;
        letter-spacing: -0.06px;
        @include breakpoint(tablet) {
            text-align: center;
        }
        .dev {
            text-align: right;
            @include breakpoint(tablet) {
                text-align: center;
            }
            a {
                font-size: 12px;
                color: #4A4743;
                letter-spacing: -0.06px;
                text-decoration: none;
                &:hover {
                    color: $vermelho;
                }
            }
        }
    }

}