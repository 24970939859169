/*!
Theme Name: Voce Resolve 2021
Theme URI: https://dominioweb.dev/
Description: Site desenvolvido especificamente para uso da Dominio WEB;
Author: Domínio WEB
Author URI: https://dominioweb.dev/
Version: 1.0
*/

$urlAssets: 'assets/';

// Imports
@import "mixins/mixins";
@import "commom/imports";
@import "commom/variables";
@import "commom/fonts";
@import "commom/default";
@import "commom/padroes";
@import "commom/keyframes";

//Components
// @import "components/label";
// @import "components/inputs";
@import "components/buttons";
// @import "components/select";
// @import "components/compartilhe";
// @import "components/owl";
// @import "components/marcador";
// @import "components/linha";
// @import "components/h2";
// @import "components/texto";
@import "components/fullTitle";

//Layout Site
// @import "parts/agegate";
@import "parts/header";

@import "templates/vitrine";
@import "templates/categorias";
@import "templates/encontrar";
// @import "templates/naMidia";

@import "pages/sobre";
@import "pages/catalogo";
@import "pages/ondeEncontrar";
@import "pages/contato";

// @import "parts/menu";
@import "parts/footer";
