/*
  SASS Variables for project
 */
$version: "v1.0.0";
$author: "Alexander Moraes";

// COLORS DEFAULT
$valid: #2ecc71;
$error: #FF0000;

$cinza: #444;
$preto: #000;

$laranja: #FF7E5E;
$laranjaHover: #DD7358;
$rosa: #EC63AA;

$azul: #002F6C;
$verde: #13670B;
$vermelho: #D00008;
$vermelhoHover: #B10007;
$cinzaClaro: #C6D4DD;
$cinza: #666666;

//Tamanho Container
$containerWidth: 1300px;

//Font Padrão do Site
$fontDefault: 'Montserrat', sans-serif;;
$fontTitulo: 'Androgyne', sans-serif;;

// font-family: 'Merriweather', serif;
// font-family: 'Poppins', sans-serif;

$corBarraResponsivo: #FFFFFF;

$coluna: 4vw;

// PATHS
$fontsSRC: $urlAssets + "fonts/";
$img: $urlAssets + "imgs/";


// BREAKPOINTS
$breakpoints: (max: (xs: 320px, mob: 660px, vertmob: 740px, tablet: 767px, deskmini: 1024px), min: (sm: 768px, md: 992px, lg: 1200px));