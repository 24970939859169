.categorias {
    display: block;
    width: 100%;
    height: auto;
    padding: 80px 0;

    a {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }

}