.btn {
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: -0.07px;
    min-height: 38px;
    &-white {
        background-color: transparent;
        padding: 14px 22px;
        color: #FFF;
        text-transform: uppercase;
        border: #FFF 1px solid;
        &:hover {
            background-color: $vermelhoHover;
            color: #fff;
        }
    }
    &-red {
        background-color: $vermelho;
        padding: 14px 22px;
        color: #FFF;
        text-transform: uppercase;
        border: 0;
        &:hover {
            background-color: $vermelhoHover;
            color: #fff;
        }
    }
}