@-webkit-keyframes upDown {
    0% { bottom: 10px; }
  100% { bottom: 0px; }
}

@-moz-keyframes upDown {
    0% { bottom: 10px; }
  100% { bottom: 0px; }
}

@-o-keyframes upDown {
    0% { bottom: 10px; }
  100% { bottom: 0px; }
}

@keyframes upDown {
    0% { bottom: 10px; }
  100% { bottom: 0px; }
}